<template>
    <div class="search-bar">
        <input   type="text"
            v-model="searchQuery"
            @input="handleInput"
            placeholder="Search..."
            class="search-input"/>


    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
          searchQuery: '',
        };
    },
    methods: {
      handleInput() {
            // Emit the search query to the parent component
            this.$emit('update:searchQuery', this.searchQuery);
        },
    },
    mounted() {
        // Initialize filteredItems with all items
        this.filteredItems = this.items;
    },
};
</script>

<style scoped>
.search-bar {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

li {
    padding: 8px;
    background: #f9f9f9;
    margin: 4px 0;
    border-radius: 4px;
}
input:focus-visible {
    outline: none;
}

</style>
